import React from "react";
import { Helmet } from "react-helmet-async";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router";
import { apiLogin, apiMe } from "../api/axios";
import { ILoginInput, ILoginOutput } from "../api/type";
import { FormButton } from "../component/form-button";
import { FormError } from "../component/form-error";
import { LOCALSTORAGE_TOKEN, UserRole, UserTestState } from "../constant";
export const TesterLogin = () => {
  const naviage = useNavigate();
  const queryClient = useQueryClient();
  useQuery("me", apiMe, {
    onSuccess: (data) => {
      if (data) {
        switch (data.testState) {
          case UserTestState.Pending:
            naviage(`/orientation?normid=${data.group[0].normId}`);
            break;

          case UserTestState.InProgress:
            naviage(`/test`);
            break;

          case UserTestState.Done:
            naviage(`/preview`);
            break;

          default:
            break;
        }
      }
    },
  });
  const {
    register,
    getValues,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm<ILoginInput>({ mode: "onChange" });

  const loginMutation = useMutation(apiLogin, {
    onSuccess: (data: ILoginOutput) => {
      if (data.ok && data.token) {
        localStorage.setItem(LOCALSTORAGE_TOKEN, data.token);
        queryClient.invalidateQueries("me");
      }
    },
  });

  const onSubmit = () => {
    if (!loginMutation.isLoading) {
      const { name, password } = getValues();
      loginMutation.mutate({ name, password, role: UserRole.Tester });
    }
  };

  return (
    <div className="h-screen flex items-center justify-center min-w-min select-none">
      <Helmet>
        <title>지원자 로그인</title>
      </Helmet>
      <div className="w-full max-w-lg">
        <div
          style={{ color: "#e6007e" }}
          className="w-full text-center text-4xl font-bold   text-pink"
        >
          LG유플러스 온라인 인터뷰
        </div>
        <div
          // style={{ backgroundColor: "rgb(0, 24, 168)" }}

          className="  w-full max-w-lg py-10 px-16 text-center"
        >
          {/* <h3 style={{ color: "#e6007e" }} className=" font-medium text-2xl ">
            지원자 로그인
          </h3> */}
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="grid gap-3  border-2 p-16 border-gray-400 rounded-md"
          >
            <input
              {...register("name", { required: "이름은 필수 입력값 입니다." })}
              placeholder="이름"
              required
              className="border-b border-gray-500 focus: outline-none my-1  py-3   "
            />
            {errors.name?.message && (
              <FormError errorMessage={errors.name?.message} />
            )}
            <input
              {...register("password", {
                required: "인증키는 필수 입력값 입니다.",
              })}
              type="password"
              placeholder="인증키"
              required
              className=" border-b border-gray-500  focus: outline-none my-1  py-3  mb-10 "
            />
            {errors.password?.message && (
              <FormError errorMessage={errors.password?.message} />
            )}
            <FormButton
              canClick={isValid}
              loading={loginMutation.isLoading}
              actionText={"로그인"}
            />
            {loginMutation.data?.error && (
              <FormError errorMessage={loginMutation.data.error} />
            )}
          </form>
        </div>
        <div className=" flex justify-center mt-5">
          <div className="flex content-end justify-end">
            <img className="h-16" src={"image/lguplus.png"} alt="lg uplus" />
          </div>
        </div>
      </div>
    </div>
  );
};
