import React from "react";
import { Helmet } from "react-helmet-async";
import { useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import { LOCALSTORAGE_TOKEN } from "../constant";

export const DualMonitor = () => {
  const queryClient = useQueryClient();

  const logout = () => {
    localStorage.removeItem(LOCALSTORAGE_TOKEN);
    queryClient.invalidateQueries("me");
  };

  return (
    <div className="h-screen flex flex-col items-center justify-center">
      <Helmet>
        <title>Not Found</title>
      </Helmet>
      <h2 className="font-semibold text-2xl mb-3">추가 모니터가 감지 되었습니다.</h2>
      <h4 className="font-medium text-base mb-5">
        재로그인 해주십시오.
      </h4>
      <Link
        onClick={logout}
        className="hover:underline text-red-500"
        to="/tester-login"
      >
        지원자 로그인 페이지 &rarr;
      </Link>
    </div>
  );
};
